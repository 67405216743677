<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template v-if="region" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mb--md mt--xl" />
      <div v-if="region.id">
        <getecma-header size="lg">Editar Rota</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-region-edit-information @save="save" />
      </div>
      <div v-if="!region_id">
        <getecma-header size="lg">Adicionar Rota</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-region-create-information @create="save" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError, toastSuccess } from '@/services/toastService';
import { goToOpenRegion, goToRegionForbiddenPage } from '@/modules/region/region.routes'; // eslint-disable-line
import { getters } from '@/modules/user/user.store';
import { getRegionById, updateRegionInformation, createNewRegion } from '@/modules/region/region.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { REGIONS_URL } from '@/modules/region/region.constants';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaRegionEditInformation from '@/modules/region/components/RegionEditComponent.vue';
import GetecmaRegionCreateInformation from '@/modules/region/components/RegionCreateComponent.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaRegionEditPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaRegionEditInformation,
    GetecmaRegionCreateInformation,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  provide() {
    const regionEditVm = {};
    Object.defineProperty(regionEditVm, 'region', {
      get: () => this.region,
    });
    return { regionEditVm };
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Rotas', path: REGIONS_URL.path },
        { name: 'Adicionar', path: this.$route.path },
      ],
      currentUser: getters.getUser(),
      region: null,
      createdRegion: null,
      fullscreenLoading: false,
    };
  },
  computed: {
    region_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    if (this.region_id) {
      this.itemsBreadcrumb[2].name = 'Editar';
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToRegionForbiddenPage(this.$router, false);
      this.onFetch();
    } else {
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToRegionForbiddenPage(this.$router, true);
      this.onFetch();
    }
  },
  methods: {
    onFetch() {
      if (this.region_id) {
        getRegionById(this.region_id)
          .then(data => {
            this.region = data;
          })
          .catch(() => toastError('Erro ao obter rota por ID'));
      } else {
        this.region = {
          name: '',
        };
      }
    },
    save() {
      if (this.region_id) {
        this.fullscreenLoading = true;
        updateRegionInformation(this.region)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Rota salva!');
            goToOpenRegion(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar a rota');
            this.fullscreenLoading = false;
          });
      } else {
        this.onCreateNewRegion(this.region);
      }
    },
    onCreateNewRegion(region) {
      createNewRegion(region)
        .then(data => {
          goToOpenRegion(this.$router, data);
        })
        .catch(() => {
          toastError('Erro ao salvar a rota');
        });
    },
  },
};
</script>
