import * as regionResource from '@/modules/region/region.resource';

export function fetchRegions(pagination = {}) {
  return new Promise((resolve, reject) =>
    regionResource
      .fetchRegions(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function fetchNeighborhoodsByRegion(idRegion) {
  return new Promise((resolve, reject) =>
    regionResource
      .fetchNeighborhoodsByRegion(idRegion)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function fetchRegionsByNeighborhood(idNeighborhood) {
  return new Promise((resolve, reject) =>
    regionResource
      .fetchRegionsByNeighborhood(idNeighborhood)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getRegionById(id) {
  return new Promise((resolve, reject) =>
    regionResource
      .getRegionsById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewRegion(region) {
  return new Promise((resolve, reject) =>
    regionResource
      .createNewRegion(region)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateRegionInformation(region) {
  return new Promise((resolve, reject) =>
    regionResource
      .updateRegionInformation(region)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeRegion(region) {
  return new Promise((resolve, reject) =>
    regionResource
      .removeRegion(region)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
