import { get, put, post, remove } from '@/helpers/http';

export function getRegionsById(id) {
  return get(`/regions/${id}`);
}

export function updateRegionInformation(region) {
  return put(`/regions/${region.id}`, region);
}

export function createNewRegion(region) {
  return post('/regions/', region);
}

export function removeRegion(region) {
  return remove(`/regions/${region.id}`);
}

export function fetchRegions(pagination = {}) {
  return get('/regions', { ...pagination });
}

export function fetchNeighborhoodsByRegion(idRegion) {
  return post('/regions-neighborhoods', { region_id: idRegion });
}

export function fetchRegionsByNeighborhood(idNeighborhood) {
  return post('/regions-neighborhood', { neighborhood_id: idNeighborhood });
}
