<template>
  <getecma-form v-if="region" :submit="save">

    <fieldset class="form-group mt--xl">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="region.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Dom Bosco" />
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Bairros*</getecma-header>
      </div>
      <div>
        <el-transfer
          v-model="routes"
          class="custom-transfer"
          filterable
          :filter-method="filterMethod"
          :titles="['Disponíveis', 'Selecionados']"
          :button-texts="['Remover', 'Adicionar']"
          filter-placeholder="Filtrar por bairro/cidade"
          :data="neighborhoods">
        </el-transfer>
      </div>
    </fieldset>
    <div class="mt--xl mb--md d--flex justify-content-end">
      <getecma-button
        size="xl"
        :round="false"
        class="fs--sm"
        @click="goHistoryBack()">
        Cancelar
      </getecma-button>
      <getecma-button
        native-type="submit"
        class="ms--md fs--sm"
        bg-color="success"
        :round="false"
        size="xl">
        Salvar
      </getecma-button>
    </div>
  </getecma-form>
</template>

<script>

import { toastError } from '@/services/toastService';
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { fetchNeighborhoods } from '@/modules/neighborhood/neighborhood.service';
import { fetchNeighborhoodsByRegion } from '@/modules/region/region.service';

export default {
  name: 'GetecmaRegionCreateInformation',
  components: {
  },
  inject: ['regionEditVm'],
  data() {
    return {
      region: this.regionEditVm.region,
      performer: getters.getUser(),
      neighborhoods: [],
      cities: [],
      routes: [],
      filterMethod(query, item) {
        return item.label && item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
      },
    };
  },
  async mounted() {
    await this.fetchNeighborhoods();
    await this.fetchNeighborhoodsByRegion();
  },
  methods: {
    goHistoryBack,
    async fetchNeighborhoods() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchNeighborhoods(params);
        this.neighborhoods = response.rows.map(neighborhood => ({
          key: neighborhood.id,
          label: neighborhood.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar os bairros:', error);
      }
    },
    fetchNeighborhoodsByRegion() {
      fetchNeighborhoodsByRegion(this.region.id)
        .then(response => {
          this.routes = response.rows.map(neighborhood => neighborhood.n_id);
        })
        .catch(() => toastError('Erro ao obter rota por ID'));
    },
    save() {
      if (this.routes.length === 0) {
        toastError('Você precisa selecionar pelo menos um bairro');
      } else {
        this.region.neighborhoods = this.routes;
        this.$emit('save');
      }
    },
  },
};
</script>

<style>
.el-transfer-panel .el-transfer-panel__header .el-checkbox .el-checkbox__label {
  font-size: 14px; /* Tamanho da fonte do título principal do componente */
}

.el-transfer-panel {
  width: 230px;
}

.custom-transfer .el-transfer-panel {
  background-color: #006588; /* Escolha a cor de fundo desejada */
}

.el-transfer-panel .el-transfer-panel__header .el-checkbox .el-checkbox__label span {
  font-size: 11px; /* Tamanho da fonte dos numeros. Ex.: 0/2 */
}

.el-transfer-panel__filter .el-input__inner {
  font-size: 11px; /* Tamanho da fonte da pesquisa do filtro*/
}

.el-transfer-panel .el-transfer-panel__empty {
  color: white;
  scrollbar-color: white;
  font-size: 11px;
}

.el-transfer-panel__item.el-checkbox {
  color: white;
  font-size: 11px;
}

.el-checkbox__label {
  font-size: 11px;
}

.el-button--primary.is-disabled {
  background-color: #006588; /* Cor de fundo do botão */
}

.el-button {
  display: block; /* Disposição do botão: agora é vertical */
}

.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
  margin-left: 0px; /* Tira o espaçamento antes do segundo botão (label: "Remover"*/
}
</style>
